import { Routes } from '@angular/router';
import { authGuardActivate, authGuardMatch, menuGuardActivate, menuGuardMatch } from '@mca/auth/api';
import { AppComponent } from './app.component';
import { notFoundGuardActivate } from '@mca/auth/domain';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [authGuardActivate],
  },
  {
    path: '',
    loadChildren: () => import('@mca/auth/feature-main-login').then(m => m.AuthFeatureMainLoginModule),
  },
  {
    path: 'mcaedit',
    loadChildren: () => import('@mca/mca/feature-main-edit').then(m => m.McaFeatureMainEditModule),
    canMatch: [authGuardMatch],
  },
  {
    path: 'workload',
    loadChildren: () => import('@mca/underwriting/feature-workload').then(m => m.WORKLOAD_ROUTES),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'assignments',
    loadChildren: () => import('@mca/mca/feature-assignments-pool').then(m => m.McaFeatureAssignmentsPoolModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'collection',
    loadChildren: () => import('@mca/collection/feature-shell').then(m => m.CollectionFeatureShellModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'references',
    loadChildren: () => import('@mca/references/feature-shell').then(m => m.ReferencesFeatureShellModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'lender',
    loadChildren: () => import('@mca/lender/feature-details').then(m => m.LenderFeatureDetailsModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'loan',
    canMatch: [authGuardMatch, menuGuardMatch],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@mca/loan/feature-dashboard').then(m => m.LOAN_DASHBOARD_ROUTES),
        canMatch: [authGuardMatch, menuGuardMatch],
      },
      {
        path: 'release-coupons',
        loadChildren: () => import('@mca/loan/feature-release-coupons').then(m => m.LOAN_RELEASE_COUPONS_ROUTES),
        canMatch: [authGuardMatch, menuGuardMatch],
      },
      {
        path: 'issuer',
        loadChildren: () => import('@mca/loan/feature-issuer').then(m => m.LOAN_ISSUER_ROUTES),
        canMatch: [authGuardMatch, menuGuardMatch],
      },
      {
        path: 'transactions',
        loadChildren: () => import('@mca/loan/feature-transactions').then(m => m.LOAN_TRANSACTIONS_ROUTES),
        canMatch: [authGuardMatch, menuGuardMatch],
      },
      {
        path: '',
        loadChildren: () => import('@mca/loan/feature-list').then(m => m.LOAN_LIST_ROUTES),
        canMatch: [authGuardMatch, menuGuardMatch],
      },
    ],
  },
  {
    path: 'iso-active-offers',
    loadChildren: () => import('@mca/isorelation/feature-iso-active-offers').then(m => m.ISO_ACTIVE_OFFERS_ROUTES),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'isorelation',
    loadChildren: () => import('@mca/isorelation/feature-tabs').then(m => m.ISORELATION_TABS_ROUTES),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'pm',
    loadChildren: () => import('@mca/pm/feature-shell').then(m => m.PmFeatureShellModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'reports',
    loadChildren: () => import('@mca/reports/feature-shell').then(m => m.ReportsFeatureShellModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'dashboard',
    canActivate: [authGuardActivate],
    data: {
      title: 'Dashboard',
      breadcrumb: {
        name: 'Dashboard',
      },
    },
    children: [
      {
        path: 'wf',
        loadChildren: () => import('@mca/reports/feature-dashboard-wf').then(m => m.ReportsFeatureDashboardWfModule),
        canMatch: [authGuardMatch, menuGuardMatch],
        data: {
          title: 'WF Dashboard',
          breadcrumb: {
            name: 'WF Dashboard',
          },
        },
      },
      {
        path: 'accounting',
        loadChildren: () => import('@mca/reports/feature-dashboard-accounting').then(m => m.ReportsFeatureDashboardAccountingModule),
        canMatch: [authGuardMatch, menuGuardMatch],
        data: {
          title: 'Accounting Dashboard',
          breadcrumb: {
            name: 'Accounting Dashboard',
          },
        },
      },
    ],
  },
  {
    path: 'transactions',
    loadChildren: () => import('@mca/transaction/feature-shell').then(m => m.TransactionFeatureShellModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'user',
    loadChildren: () => import('@mca/user/feature-edit').then(m => m.UserFeatureUserEditModule),
    canMatch: [authGuardMatch, menuGuardMatch],
    data: {
      breadcrumb: {
        name: 'Users',
        link: '/references/users',
      },
    },
  },
  {
    path: 'system',
    loadChildren: () => import('@mca/system/feature-shell').then(m => m.SystemFeatureShellModule),
    canMatch: [authGuardMatch, menuGuardMatch],
  },
  {
    path: 'events',
    canActivate: [authGuardActivate, menuGuardActivate],
    children: [
      {
        path: 'underwriter',
        loadChildren: () => import('@mca/underwriting/feature-funding-review').then(m => m.UnderwritingFeatureFundingReviewModule),
        canMatch: [menuGuardMatch],
        data: {
          title: 'Transactions',
          breadcrumb: {
            name: 'Funding Review',
          },
        },
      },
      {
        path: 'docusign',
        loadChildren: () => import('@mca/underwriting/feature-docusign-status').then(m => m.UNDERWRITING_DOCUSIGN_STATUS_ROUTES),
        canMatch: [menuGuardMatch],
        data: {
          breadcrumb: {
            name: 'Docusign Status',
          },
        },
      },
      {
        path: 'accounting',
        loadChildren: () => import('@mca/accounting/feature-events').then(m => m.AccountingFeatureEventsModule),
        canMatch: [menuGuardMatch],
      },
      {
        path: 'contract',
        loadChildren: () => import('@mca/mca/feature-contract-events').then(m => m.McaFeatureContractEventsModule),
      },
    ],
  },
  {
    path: '**',
    component: AppComponent,
    canActivate: [notFoundGuardActivate],
  },
];
